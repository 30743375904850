@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-Light.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-LightItalic.woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-Bold.woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-BoldItalic.woff");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-Regular.woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Lab Grotesque";
    src: url("./fonts/LabGrotesqueWeb-Italic.woff");
    font-weight: 500;
    font-style: italic;
}
